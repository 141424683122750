import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-hamburger').on('click', (event) => {
  $('.js-hamburgerLine').toggleClass(active);
  $('#js-nav').slideToggle();

  return false;
});
