import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

setTimeout(function() {

var swiper = new Swiper("#js-hero .swiper", {
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    769: {
      pagination: false,
    },
  },
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  spaceBetween: 0,
  speed: 2000,
});

}, 1500);

