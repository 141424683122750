import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

let cnt = $('#js-recommendMenu .swiper .swiper-slide').length;

if(cnt > 3) {
  cnt = 3;
}

const swiper = new Swiper('#js-recommendMenu .swiper', {
  breakpoints: {
    769: {
      slidesPerView: cnt,
      spaceBetween: 50,
    },
  },
  navigation: {
    nextEl: "#js-recommendMenu .swiper-button-next",
    prevEl: "#js-recommendMenu .swiper-button-prev",
  },
  slidesPerView: 2,
  spaceBetween: 10,
});
